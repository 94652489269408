.box-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 290px;
  z-index: 9999;
  box-shadow: none;
}

  .inputStyle {
    width: 50% !important;
    height: 64px;
    border-radius: 7px;
    border: 0px;
    margin-left: 8px;
    margin-right: 8px;
    background: #EEECF6;
    
    font-size: 20px;
    color: black;
    
  }
  .inputStyle:focus-visible {
    outline: #F69C2B auto 1px;
    background-color: white;
   
    color: black;
}
.button-confirmar {
  background-color: #F69C2B;
  box-shadow: none !important;
  width: 100%;
  height: 37px;
  border-radius: 12px;
}
.button-confirmar:hover {
  background-color: #FD8D00;
}
.grid-otp {
  gap: 10px;
}
.button-cancelar {
  border: 1px solid #DED8E1;
  width: 100%;
  height: 37px;
  border-radius: 12px;
}