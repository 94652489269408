

.box{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.text_original {
  display: block;
  
}

.text_cambio {
  display: none;
}

.MuiTypography-root:hover > .text_original {
  display: none;
}

.MuiTypography-root:hover > .text_cambio {
  display: block;
}
