
.chip-inactivo {
    background: #CCCCCC;
    border-radius: 2px;
    padding: 5px;
    width: fit-content;
    margin-left: 5px;
    font-weight: 600;
}
.tabs-restaurant {
    width: 100vw;
}