

  *::-webkit-scrollbar {
    display: none;
  }

  * {
    scroll-behavior: smooth;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  